<template>
    <GestionLayout>
        <form slot="leftContainer" autocomplete="nope" @submit.prevent="addUser">
            <v-card>
                <v-card-title class="primary white--text subtitle">
                    <b>AGREGAR COLABORADORES </b>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <v-col class="d-flex pa-2"><h2>Datos Personales</h2></v-col>

                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="form.id_documento"
                                    v-validate="'required'"
                                    :items="selectTiDocument"
                                    placeholder="DNI"
                                    item-text="text"
                                    item-value="id"
                                    :error-messages="errors.collect('form.id_documento')"
                                    label="Tipo de documento"
                                    data-vv-name="form.id_documento"
                                    data-vv-as="Tipo"
                                    required
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.documento"
                                    v-validate="'required'"
                                    placeholder="44826152"
                                    data-vv-name="form.documento"
                                    data-vv-as="Documento de Identidad"
                                    :error-messages="errors.collect('form.documento')"
                                    label="Documento de Identidad"
                                    @keypress="isNumber"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.correo"
                                    v-validate="'required'"
                                    placeholder="innout@gmail.com"
                                    data-vv-name="form.correo"
                                    data-vv-as="Correo Electronico"
                                    :error-messages="errors.collect('form.correo')"
                                    label="Correo Electronico"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.nombres"
                                    v-validate="'required'"
                                    data-vv-name="form.nombres"
                                    placeholder="Luis"
                                    data-vv-as="nombres"
                                    :error-messages="errors.collect('form.nombres')"
                                    label="Nombres"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.apellidos"
                                    v-validate="'required'"
                                    placeholder="Flores"
                                    data-vv-name="form.apellidos"
                                    data-vv-as="apellidos"
                                    :error-messages="errors.collect('form.apellidos')"
                                    label="Apellidos"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="form.idSede"
                                    v-validate="'required'"
                                    :items="findSede"
                                    item-text="sede"
                                    item-value="id"
                                    :error-messages="errors.collect('form.idSede')"
                                    label="Sede"
                                    data-vv-name="form.idSede"
                                    data-vv-as="Sedes"
                                    required
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="form.id_role"
                                    v-validate="'required'"
                                    :items="_selectCargo"
                                    item-text="text"
                                    item-value="id"
                                    :error-messages="errors.collect('form.id_role')"
                                    label="Puesto"
                                    data-vv-name="form.id_role"
                                    data-vv-as="Tipo"
                                    required
                                />
                                <v-checkbox v-if="form.id_role == 6" @click="assginedVendor" label="¿Vendedor Junior?"></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox @click="dialog = true" v-model="checkbox" :label="`Escoger los modulos `"></v-checkbox>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="form.telefono"
                                    data-vv-name="form.telefono"
                                    data-vv-as="Telf. contacto"
                                    placeholder="986193329"
                                    :error-messages="errors.collect('form.telefono')"
                                    label="Telf. contacto"
                                    @keypress="isNumber"
                                />
                            </v-col>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> Información Adicional </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12">
                                            <v-menu
                                                v-model="menu4"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.extraInformation.fechaNacimiento"
                                                        data-vv-name="form.extraInformation.fechaNacimiento"
                                                        prepend-icon="mdi-calendar"
                                                        placeholder="27-02-2023"
                                                        data-vv-as="Fecha Nacimiento"
                                                        label="Fecha Nacimiento"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="form.extraInformation.fechaNacimiento" @input="menu4 = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field v-model="form.extraInformation.direccion" label="Dirección domicilio" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.extraInformation.celular"
                                                placeholder="986123882"
                                                label="Nº de celular"
                                                @keypress="isNumber"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="form.extraInformation.estadoCivil" label="Estado civil" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="form.extraInformation.correo" placeholder="edad" label="Correo personal" />
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> Datos Familiares </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.familiar.nombreCompleto"
                                                placeholder="Luis"
                                                label="Nombre Completo de Contacto de Emergencia"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="form.familiar.telefono" placeholder="986193329" label="Nº Telefónico" @keypress="isNumber" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="form.familiar.nombreHijo" label="Nombre Hijo" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="form.familiar.edadHijo" @keypress="isNumber" label="Edad Hijo" />
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> Pagos </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.pago.cuentaBancaria"
                                                data-vv-name="form.pago.cuentaBancaria"
                                                placeholder="1234 1234 12 1234567890"
                                                data-vv-as="Cuenta Bancaria"
                                                :error-messages="errors.collect('form.pago.cuentaBancaria')"
                                                label="cuenta bancaria"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.pago.banco"
                                                data-vv-name="form.pago.banco"
                                                placeholder="BCP"
                                                data-vv-as="Banco"
                                                :error-messages="errors.collect('form.pago.banco')"
                                                label="Banco"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.pago.cci"
                                                data-vv-name="form.pago.cci"
                                                placeholder="YXY"
                                                data-vv-as="CCI"
                                                :error-messages="errors.collect('form.pago.cci')"
                                                label="CCI"
                                            />
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> Datos Contrato </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.sueldo"
                                                data-vv-name="form.sueldo"
                                                placeholder="S/.1025"
                                                data-vv-as="CCI"
                                                @keypress="isNumber"
                                                :error-messages="errors.collect('form.sueldo')"
                                                label="Sueldo"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.contrato.cargo"
                                                data-vv-name="form.contrato.cargo"
                                                placeholder="Vendedor"
                                                data-vv-as="cargo"
                                                :error-messages="errors.collect('form.contrato.cargo')"
                                                label="Cargo"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="form.contrato.formaPago"
                                                data-vv-name="form.contrato.formaPago"
                                                data-vv-as="Form Pago"
                                                :error-messages="errors.collect('form.contrato.formaPago')"
                                                label="Forma Pago"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-menu
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.contrato.fechaIngreso"
                                                        data-vv-name="form.contrato.fechaIngreso"
                                                        prepend-icon="mdi-calendar"
                                                        placeholder="27-02-2023"
                                                        data-vv-as="Fecha Ingreso"
                                                        :error-messages="errors.collect('form.contrato.fechaIngreso')"
                                                        label="Fecha Ingreso"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="form.contrato.fechaIngreso" @input="menu = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-menu
                                                ref="menu2"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="form.contrato.horaIngreso"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.contrato.horaIngreso"
                                                        data-vv-name="form.contrato.horaIngreso"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        placeholder="8:00 am"
                                                        data-vv-as="Horario de ingreso"
                                                        label="Horario de ingreso"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-if="menu2"
                                                    v-model="form.contrato.horaIngreso"
                                                    full-width
                                                    @click:minute="$refs.menu2.save(form.contrato.horaIngreso)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-menu
                                                ref="menu3"
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="form.contrato.horaSalida"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.contrato.horaSalida"
                                                        data-vv-name="form.contrato.horaSalida"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        placeholder="8:00 am"
                                                        data-vv-as="Horario de salida"
                                                        label="Horario de salida"
                                                    />
                                                </template>
                                                <v-time-picker
                                                    v-if="menu3"
                                                    v-model="form.contrato.horaSalida"
                                                    full-width
                                                    @click:minute="$refs.menu3.save(form.contrato.horaSalida)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" v-if="showUserAdmin || user.id_role == 3">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header> USUARIO - LOGIN </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-col cols="12" class="grey lighten-5">
                                            <v-container fluid>
                                                <v-row dense>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="form.usuario"
                                                            v-validate="'required'"
                                                            data-vv-name="form.usuario"
                                                            data-vv-as="User"
                                                            :error-messages="errors.collect('form.usuario')"
                                                            label="Usuario"
                                                        />
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-col cols="12" mt-2>
                                                            <v-text-field
                                                                id="_loginPromotor"
                                                                v-model="form.password"
                                                                v-validate="'required'"
                                                                class="ld-form-input"
                                                                :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                                                                :type="show ? 'text' : 'form.password'"
                                                                name="input-10-1"
                                                                :error-messages="errors.collect('form.password')"
                                                                label="Contraseña"
                                                                data-vv-name="form.password"
                                                                autocomplete="off"
                                                                required
                                                                data-vv-as="contraseña"
                                                                @click:append="show = !show"
                                                            />
                                                        </v-col>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-dialog v-model="dialog" persistent max-width="290">
                            <v-card class="mx-auto" max-width="300" tile>
                                <v-list dense>
                                    <v-list-group :prepend-icon="item.icon" :value="key == 0" v-for="(item, key) in _listModules" :key="key">
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.modules }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item v-for="(itemChild, keyChild) in item.items" :key="keyChild">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-checkbox v-model="form.optionalRole.modules" :label="`${itemChild.title}`" :value="itemChild.id">{{
                                                        itemChild.title
                                                    }}</v-checkbox>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" text @click="saveModule"> Guardar </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col cols="12">
                        <v-btn type="submit" dark :color="step == 1 ? 'primary' : 'orange'" block>
                            <b>{{ step == '1' ? 'Agregar' : 'Actualizar' }}</b>
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </form>

        <v-card slot="rightContainer" text>
            <v-card-title>
                <b>COLABORADORES</b>
                <v-spacer />
                <v-text-field v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
            </v-card-title>
            <v-card-text>
                <v-data-table multi-sort :search="search" :headers="headers" :items="_listaUser" class="elevation-1" :items-per-page="20">
                    <template v-slot:[`item.nameStatus`]="{ item }">
                        {{ item.nameStatus }}
                        <v-icon v-if="item.status" color="green lighten-2" small @click="changeStatus(item)">mdi-check</v-icon>
                        <v-icon v-if="!item.status" color="red lighten-2" small @click="changeStatus(item)">mdi-close</v-icon>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-icon color="green lighten-2" small @click="editItem(item)">mdi-pencil</v-icon>
                        <!-- <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon> -->
                    </template>
                    <template v-slot:[`item.contrasenia`]="{ item }">
                        <div v-if="item.contrasenia">login</div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </GestionLayout>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import GestionLayout from '../../layout/GestionLayout'
import { sanitizeData } from '@/utils/genericUtils'

const defaultForm = {
    id_documento: 1,
    id_role: 1,
    cargo: 'MASAJISTA',
    documento: null,
    usuario: null,
    password: null,
    nombres: null,
    correo: null,
    apellidos: null,
    newVendor: false,
    extraInformation: { fechaNacimiento: '', direccion: null, celular: null, estadoCivil: null, correo: null },
    familiar: { nombreCompleto: '', telefono: null, nombreHijo: null, edadHijo: null },
    pago: { cuentaBancaria: null, banco: null, cci: null },
    contrato: {
        cargo: null,
        formaPago: null,
        fechaIngreso: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        horaIngreso: null,
        horaSalida: null
    },
    sueldo: null,
    optionalRole: { modules: [] }
}

export default {
    components: {
        GestionLayout
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            checkbox: false,
            search: '',
            show: false,
            showUserAdmin: false,
            showSucces: { state: false, text: 'Agregado exitosamente' },
            loading: false,
            form: defaultForm,
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            name: null,
            step: 1,
            dialog: false,
            selectRole: [
                { id_role: 1, text: 'No' },
                { id_role: 2, text: 'SI' }
                /* { id_role: 3, text: "SUPER-USER" } */
            ],
            headers: [
                { text: 'Nombres', value: 'nombres' },
                { text: 'Apellidos', value: 'apellidos' },
                { text: 'Cargo', value: 'cargo' },
                { text: 'Sede', value: 'sede' },
                { text: 'Correo', value: 'correo' },
                { text: 'Telf.', value: 'telefono' },
                { text: 'Estado Login', value: 'contrasenia' },
                { text: 'Estado', value: 'nameStatus' },
                { text: 'Actions', value: 'action', sortable: false }
            ]
        }
    },
    computed: {
        ...mapState('utilities', ['selectCargo', 'tipoComprobante', 'tipoPago', 'tipoMoneda', 'tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),
        ...mapState('users', ['listaUser', 'personal', 'user', 'listModules']),
        ...mapState('sedes', ['sedes']),
        findSede() {
            let allSedes = []
            this.sedes.forEach((element) => {
                allSedes.push({ sede: element.sede_name_id, id: element.id })
            })
            return allSedes
        },
        _listaUser() {
            let colaboradores = []
            for (const item of this.listaUser) {
                const status = item.status ? 'Alto' : 'Baja'
                item.nameStatus = status
            }
            if (this.listaUser.length > 0) {
                this.listaUser.map((x) => {
                    if (x.status) {
                        const searchSede = this.findSede.find((a) => a.id == x.idSede)
                        //console.log('this.selectCargo ', this.selectCargo)
                        const item = this.selectCargo.find((y) => y.id === x.id_role)
                        //console.log('item ', item)
                        colaboradores.push({ ...x, cargo: item ? item.text : '', sede: searchSede.sede })
                    }
                })
            }
            const registro = [2, 3].includes(this.user.id_role) ? colaboradores : colaboradores.filter((x) => x.idSede == this.user.idSede)
            return registro
        },
        _listModules() {
            const modules = []
            let group = this.listModules.reduce((r, a) => {
                r[a.idModule] = [...(r[a.idModule] || []), a]
                return r
            }, {})
            for (const key in group) {
                modules.push({ modules: key, items: group[key] })
            }
            return modules
        },
        id_role() {
            return this.form.id_role
        },
        selectTiDocument() {
            return this.tipoDocumento
        },
        _selectCargo() {
            return this.selectCargo
        }
    },
    watch: {},
    async created() {
        await this.getLista()
        await await this.$store.dispatch('users/listModules')
        if (this.user.id_role == 2) {
            this.showUserAdmin = true
        }
    },
    methods: {
        async addUser() {
            this.$validator
                .validateAll()
                .then(async (result) => {
                    console.log('result', result)
                    if (result) {
                        this.loading = true
                        let text = 'Agregado exitosamente'
                        const dataSanitize = sanitizeData(this.form)
                        if (this.step == 1) {
                            const myData = await this.$store.dispatch('users/postUser', dataSanitize)
                            if (myData.codRes != 200) {
                                text = 'Usuario Ya Existe'
                                this.$store.commit('mainUI/OPEN_BAR', {
                                    state: true,
                                    text,
                                    timeout: 5000
                                })
                                //exit
                                return
                            }
                        } else {
                            /* update */
                            await this.$store.dispatch('users/updateUser', { ...dataSanitize, usuario: this.form.usuario })
                            text = 'Actualizado exitosamente'
                        }
                        this.step = 1
                        this.loading = true
                        this.form = {
                            id_documento: 1,
                            documento: null,
                            id_role: 1,
                            cargo: 'MASAJISTA',
                            price: null,
                            nombres: null,
                            apellidos: null,
                            newVendor: false,
                            extraInformation: { fechaNacimiento: '', direccion: null, celular: null, estadoCivil: null, correo: null },
                            familiar: { nombreCompleto: '', telefono: null, nombreHijo: null, edadHijo: null },
                            pago: { cuentaBancaria: null, banco: null, cci: null },
                            contrato: {
                                cargo: null,
                                formaPago: null,
                                fechaIngreso: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
                                horaIngreso: null,
                                horaSalida: null
                            },
                            sueldo: null,
                            optionalRole: { modules: [] }
                        }
                        await this.getLista()
                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text
                        })
                    }
                })
                .catch((e) => {
                    console.log('error ', e)
                })
        },
        assginedVendor() {
            this.form.newVendor = !this.form.newVendor
        },
        async changeStatus(item) {
            if (item.status) {
                item.status = false
                await this.$store.dispatch('users/delUser', item)
                await this.getLista()
            } else {
                item.status = true
                await this.$store.dispatch('users/updateUser', item)
                await this.getLista()
            }
            this.$store.commit('mainUI/OPEN_BAR', {
                state: true,
                text: 'Se actualizo correctamente'
            })
        },
        // async deleteItem(item) {
        //     await this.$store.dispatch('users/delUser', item)
        //     await this.getLista()
        //     this.$store.commit('mainUI/OPEN_BAR', {
        //         state: true,
        //         text: 'Eliminado exitosamente'
        //     })
        // },
        closeModule() {
            this.checkbox = false
            this.dialog = false
        },
        saveModule() {
            this.checkbox = true
            this.dialog = false
        },
        editItem(item) {
            //console.log("item--",item)
            const { additionalInformation } = item
            this.form.newVendor = item.optionalRole?.newVendor ? true : false
            this.form = item
            this.form.id_documento = String(item.id_documento)
            this.step = 2
            //console.log('item ', item)
            //console.log('additionalInformation', additionalInformation)
            if (this.user.id_role == 2) {
                this.showUserAdmin = true
            }
            if (additionalInformation) {
                const { familiar, pago, contrato, extraInformation } = additionalInformation
                this.form.familiar = familiar || {}
                this.form.extraInformation = extraInformation || {}
                this.form.pago = pago || {}
                this.form.contrato = contrato || {}
            } else {
                this.form.extraInformation = {}
                this.form.familiar = {}
                this.form.pago = {}
                this.form.contrato = {}
            }
            console.log('fin_=')
        },
        async getLista() {
            await this.$store.dispatch('users/getLista')
        }
    }
}
</script>
